import { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Send, Car, Search, Cross, X } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { SendHorizontal, Banknote, SlidersHorizontal } from "lucide-react";
import useMediaQuery from "../hooks/useMediaQuery.tsx";
import SendButton from "../assets/images/Button.svg";
import ChatIcon from "../assets/images/chat.svg";
const quickOptions = [
  { id: "running_cost", text: "What are the best features of this car?" },
  { id: "specs", text: "How does it compare to a similar model?" },
  // { id: "customer-review", text: "Customer Reviews" },
];
const quickOptionsWithIcon = [
  { id: "running_cost", text: "Running Costs", icon: <Banknote className="h-6 w-6 me-3" />},
  { id: "specs", text: "Specifications", icon: <SlidersHorizontal className="h-5 w-5 me-3" />},
];
export default function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSocket, setIsLoadingSocket] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [socket, setSocket] = useState(null);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [featuresArray, setFeaturesArray] = useState([]);
  const [vrm, setVrm] = useState("");
  const [hex, setHex] = useState("#2563eb");
  const screen = useMediaQuery("(max-width: 490px)");
  const chatContainerRef = useRef(null); // Ref for the container holding the chat messages
  
  // console.log(screen);

  // const scrollToBottom = () => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  
  useEffect(() => {
    let newSocket;
    newSocket = new WebSocket(
      "wss://car-iq-backend.prod.autofintech.link/ws/chat"
    );
    newSocket.onopen = () => {
      setIsLoadingSocket(true);
      // appendMessage(
      //   "I'm here to help answer questions about the selected car",
      //   "bot"
      // );
      console.log("WebSocket connected");
    };
    newSocket.onmessage = (event) => {
      const response = event.data;

      let cleanedResponse = extractArray(response);
      const formattedMessage = cleanedResponse[1].replace(/\*\*/g, "");
      setIsLoading(false);
      if (cleanedResponse[0] > 0 && cleanedResponse[1]) {
        appendMessage(formatMessageWithBoldAndBullets(formattedMessage), "bot");
      }
      if (!cleanedResponse[0]) {
        appendMessage(formatMessageWithBoldAndBullets(formattedMessage), "bot");
      }
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const urls = response.match(urlRegex);
      // if (urls) {
      //   appendMessage("Here is your link:", "bot");
      //   urls.forEach((url) => {
      //     appendMessage(url, "bot");
      //   });
      // }
    };
    newSocket.onclose = () => {
      console.log("WebSocket disconnected");
    };
    setSocket(newSocket);
    return () => {
      if (newSocket) {
        newSocket.close();
      }
    };
  }, []);

  useEffect(() => {
    // Get the query parameter 'vrm' from the URL
    const params = new URLSearchParams(window.location.search);
    const vrmValue = params.get("vrm");
    const hexValue = params.get("hex");
    const button = params.get("button");
    
    if (isLoadingSocket) {
      if (vrmValue) {
        setVrm(vrmValue);
        socket.send(vrmValue);
      }
      if (hexValue) {
        setHex(`#${hexValue}`);
      }
      if (button) {
        const option = quickOptions.find((option) => option.id === button);
        // console.log(option.text);
        handleOnClickFeature(option.text);
      }
    }
    // If vrmValue exists, save it to the state and log it
  }, [isLoadingSocket]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      // messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [input]);


  useEffect(() => {
    if (messagesEndRef.current && screen) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  console.log(screen);
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (socket) {
        socket.close();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [socket]);

  function formatMessageWithBoldAndBullets(message) {
    const lines = message.split("\n"); // Split message by line breaks
    let formattedElements = []; // Holds all formatted JSX elements
    let currentListItems = []; // Collects items for a <ul>
    let currentIndentLevel = 0; // Track the current indentation level

    lines.forEach((line, index) => {
      // Bold formatting for numbered sections (e.g., "1. Engine and Performance")
      const boldMatch = line.match(/^(\d+\.\s*)(.+)/); // Captures the number and section title
      if (boldMatch) {
        // Close any open lists before adding a new section
        if (currentListItems.length > 0) {
          formattedElements.push(
            <ul key={`ul-${index}`}>{currentListItems}</ul>
          );
          currentListItems = [];
          currentIndentLevel = 0;
        }
        // Push the bold section title
        formattedElements.push(
          <p key={index}>
            <strong>
              {boldMatch[1]}
              {boldMatch[2]}
            </strong>
          </p>
        );
      }
      // Handle bullet point formatting for lines starting with '-'
      else if (line.trim().startsWith("-")) {
        const indentMatch = line.match(/^(\s*)-/); // Match indentation and hyphen
        const indentLevel = indentMatch ? indentMatch[1].length : 0; // Calculate the level of indentation
        const trimmedText = line.slice(indentLevel + 1).trim(); // Remove the hyphen and spaces

        // Handle nested lists by checking the indentation level
        if (indentLevel > currentIndentLevel) {
          currentListItems.push(
            <ul key={`nested-ul-${index}`}>
              <li key={`li-${index}`}>{trimmedText}</li>
            </ul>
          );
        } else {
          // If indentation is the same or lower, continue adding to the current list
          currentListItems.push(<li key={index}>{trimmedText}</li>);
        }

        // Update current indentation level
        currentIndentLevel = indentLevel;
      }
      // Handle normal text (non-bullet points)
      else if (line.trim()) {
        // Close any open lists before adding normal text
        if (currentListItems.length > 0) {
          formattedElements.push(
            <ul key={`ul-${index}`}>{currentListItems}</ul>
          );
          currentListItems = [];
          currentIndentLevel = 0;
        }
        formattedElements.push(<p key={index}>{line}</p>); // Push normal text
      }
    });

    // Close any remaining open lists
    if (currentListItems.length > 0) {
      formattedElements.push(<ul key="ul-final">{currentListItems}</ul>);
    }

    return formattedElements;
  }

  const appendMessage = (content, sender) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: uuidv4(), text: content, sender },
    ]);
    const scrollbar = document.getElementsByClassName('custom-scrollbar');

    // Check if the scrollbar container exists
    if (scrollbar.length > 0) {
      const container = scrollbar[0]; // Get the first container
      container.scrollTop = container.scrollHeight; // Scroll to the bottom of the container
    }
  
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowWelcome(false);
    setIsLoading(true);

    if (!input.trim() || !socket) return;

    if (input && socket.readyState === WebSocket.OPEN) {
      socket.send(input);
      appendMessage(input, "user");
      setInput("");
    }

    // setIsLoading(false);
  };

  // const handleOnClickCarName = async (id, carName) => {
  //   if (socket) {
  //     setShowWelcome(false);
  //     setIsLoading(true);
  //     if (!socket) return;
  //     appendMessage(carName, "user");
  //     socket.send(id);
  //     setInput("");
  //     setIsCarNameClicked(true);
  //   }
  // };

  function adjustHexColor(hex, percent) {
    const num = parseInt(hex.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;

    return `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`;
  }

  const lightToDarkVariants = [
    adjustHexColor(hex, 50),
    adjustHexColor(hex, 40), // 40% lighter
    adjustHexColor(hex, 20), // 20% lighter
    hex, // Original color
    adjustHexColor(hex, -20), // 20% darker
    adjustHexColor(hex, -40), // 40% darker
  ];

  // console.log('Light to Dark Variants:', lightToDarkVariants);

  const handleOnClickFeature = async (value) => {
    if (socket) {
      setShowWelcome(false);
      setIsLoading(true);
      if (!socket) return;
      appendMessage(value, "user");
      socket.send(value);
      setInput("");
      // scrollToBottom();
      // setIsCarNameClicked(true);
    }
  };

  const handleKeyDown = (e) => {
    if (!isLoading) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        setIsLoading(true);
        handleSubmit(e);
      }
    }
  };

  const extractArray = (response) => {
    const arrayRegex = /\[([^\]]+)\]/;
    const arrayMatch = response.match(arrayRegex);

    let extractedArray = [];
    if (arrayMatch && arrayMatch[1]) {
      extractedArray = arrayMatch[1].split(",").map((item) => item.trim());
      setFeaturesArray(extractedArray);
    }
    // console.log(extractedArray);
    return [extractedArray.length, response.replace(arrayRegex, "").trim()];
  };

  // console.log(featuresArray);

  const parseBoldText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  const chatVariants = {
    closed: { opacity: 0, scale: 0.8, y: 100 },
    open: { opacity: 1, scale: 1, y: 0 },
  };

  const messageVariants = {
    initial: { opacity: 0, y: 20, scale: 0.9 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, scale: 0.9, transition: { duration: 0.2 } },
  };

  const sendMessageVariants = {
    initial: { scale: 1 },
    animate: {
      scale: [1, 1.2, 0.8, 1.1, 1],
      rotate: [0, 10, -10, 5, 0],
      transition: { duration: 0.5 },
    },
  };
  const welcomeVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.5, duration: 0.5 },
    },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
  };
  
  const sendInteractivityReportToParent = () => { 
    const message = { type: 'chatbotupdate', value: 1 }; 
    window.parent.postMessage(message, '*'); // Replace '*' with the parent's specific origin for security }
  }


  console.log("messagess",messages)
  return (
    <div className="max-w-6xl mx-auto overflow-y-hidden h-full"  onClick={sendInteractivityReportToParent}>
      <AnimatePresence tabIndex={-1}>
        {isLoadingSocket && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={chatVariants}
            transition={{ type: "spring", stiffness: 400, damping: 30 }}
            className="h-500 overflow-hidden min-[490px]:h-[500px] flex flex-col relative"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            <div className="overflow-y-auto overflow-x-hidden text-sm px-4 space-y-4 custom-scrollbar">
            {
              <div className=" my-12 hidden min-[500px]:block mx-2 text-center">
                <img className="mx-auto" src={ChatIcon} alt=""/>
                <h3 className="text-2xl font-medium mt-[-16px]">Chat with our AI Chatbot</h3>
                <p className="mb-3 text-base">Ask any question and our AI will answer!</p>

                <div className="w-full flex gap-2 ">
                  {quickOptionsWithIcon.map(
                    (option) =>
                      option.text !== undefined && (
                        <button
                          key={option.id}
                          onClick={() => handleOnClickFeature(option.text)}
                          disabled={isLoading}
                          className="text-base p-4 rounded-lg bg-[#F6F6F6] transition-colors disabled:opacity-50 w-6/12 text-start border-gray-[#EDEDED] border flex items-center"
                        >
                          {option.icon}{option.text}
                        </button>
                      )
                  )}
                </div>
              </div>
            }
              <AnimatePresence initial={false}>
                {messages.map((message) => (
                  <motion.div
                    key={message.id}
                    variants={messageVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className={`flex ${
                      message.sender === "user"
                        ? "justify-end"
                        : "justify-start"
                    } mb-4`}
                  >
                    <div
                      className={`flex items-end space-x-2 sm:max-w-[75%] w-full text-sm ${
                        message.sender === "user"
                          ? "flex-row-reverse space-x-reverse"
                          : ""
                      }`}
                    >
                      <div
                        className={`py-3 px-4 rounded-2xl shadow-md text-sm  ${
                          message.sender === "user"
                            ? `text-white rounded-br-none`
                            : "bg-gray-200 text-gray-800 rounded-bl-none"
                        }`}
                        style={
                          message.sender === "user"
                            ? { backgroundColor: hex } // Apply style when message.sender is "user"
                            : {}
                        }
                      >
                        <span className="text-xs md:text-sm break-words whitespace-pre-wrap">
                          {message.text}
                        </span>
                      </div>
                    </div>
                  </motion.div>
                ))}
                <div
                  ref={messagesEndRef}
                  className="block min-[500px]:hidden"
                />
              </AnimatePresence>

              {isLoading && (
                <div className="flex justify-center">
                  <motion.div
                    className="bg-gray-200 p-3 rounded-full flex items-center space-x-2"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      repeat: Infinity,
                      duration: 1,
                      repeatType: "reverse",
                    }}
                  >
                    <motion.div
                      animate={{
                        x: [0, 30, 0],
                        transition: {
                          duration: 1.5,
                          repeat: Infinity,
                          ease: "linear",
                        },
                      }}
                    >
                      <Car size={24} style={{ color: hex }} />
                    </motion.div>
                  </motion.div>
                </div>
              )}
            </div>
            {
              <div className=" mt-2 mb-0 sm:mb-12 min-[500px]:block mx-4">
                <p className="text-[#757676] mb-2 text-sm">
                Try asking the AI Chatbot
                </p>

                <div className="w-full flex flex-wrap gap-2">
                  {quickOptions.map(
                    (option) =>
                      option.text !== undefined && (
                        <button
                          key={option.id}
                          onClick={() => handleOnClickFeature(option.text)}
                          disabled={isLoading}
                          className="text-sm py-1.5 px-3 rounded-lg bg-[#F6F6F6] transition-colors disabled:opacity-50 w-full sm:w-[49%] text-start border-gray-[#EDEDED] border mb-2 sm:mb-0"
                        >
                          {option.text}
                        </button>
                      
                      )
                  )}
                </div>
              </div>
            }
            <div className="min-[500px]:h-fit  mt-2 ">
              <div className=" min-[500px]:relative w-full">
                <form
                  onSubmit={handleSubmit}
                  className="flex w-full items-center gap-3 lg:justify-between"
                >
                  <div className="relative w-full ">
                    <textarea
                      ref={textareaRef}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      disabled={isLoading}
                      placeholder="Ask about a car..."
                      className="w-full mb-3 min-[500px]:mb-0  hidden min-[500px]:block px-4 py-3 placeholder:text-sm bg-[#FAFAFA] text-gray-800 border min-h-12 max-h-32 border-gray-[#EDEDED] rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-300 resize-none overflow-hidden disabled:cursor-not-allowed placeholder:flex placeholder:justify-center rounded-[5rem]"
                      rows={1}
                    />
                    <textarea
                      ref={textareaRef}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      disabled={isLoading}
                      placeholder="Ask about a car..."
                      className=" w-full px-4 py-3 min-[500px]:hidden placeholder:text-sm bg-[#FAFAFA] text-gray-800 border min-h-12 max-h-32 border-gray-[#EDEDED] rounded-xl focus:outline-none focus:ring-1 focus:ring-gray-300  resize-none overflow-hidden disabled:cursor-not-allowed placeholder:flex placeholder:justify-center rounded-[5rem]"
                      rows={1}
                    />

                    {/* <Search
                      className="absolute left-3 top-4 text-gray-400"
                      size={18}
                    /> */}
                    <motion.button
                      type="submit"
                      className="hidden min-[500px]:flex text-white rounded-lg transition-colors disabled:opacity-50 right-1 absolute top-[4px]"
                      // style={{ backgroundColor: hex }}
                      disabled={isLoading}
                      variants={sendMessageVariants}
                      whileTap="animate"
                    >
                      {/* <Send size={20} /> */}
                      <img src={SendButton} alt="Send" width={42}/>
                    </motion.button>
                    <motion.button
                      type="submit"
                      className="text-brand-200 flex min-[500px]:hidden  transition-colors disabled:opacity-50 right-1 absolute top-[4px]"
                      // style={{ backgroundColor: hex }}
                      disabled={isLoading}
                      variants={sendMessageVariants}
                      whileTap="animate"
                    >
                      {/* <SendHorizontal size={20} /> */}
                      <img src={SendButton} alt="Send" width={42}/>
                    </motion.button>
                  </div>
                </form>
              </div>
            </div>
            {/* {!showWelcome && (
                <div className="flex md:grid grid-cols-3 md:overflow-x-hidden overflow-x-scroll gap-4 mt-1 mx-5 pb-2 md:pb-0 custom-scrollbar">
                  {featuresArray.map((option) => (
                    <button
                      key={option}
                      onClick={() => handleOnClickFeature(option)}
                      disabled={isLoading}
                      className="text-sm py-1.5 px-3 rounded-lg shrink-0  transition-colors disabled:opacity-50 w-56 sm:w-full"
                      style={{
                        backgroundColor: lightToDarkVariants[0],
                        color: hex,
                      }}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )} */}
            {/* <div className="mx-4 text-slate-700 py-1 px-2 rounded-md mt-2"
              style={{ backgroundColor: lightToDarkVariants[0] }} >
              Theo, the AI agent, is in Beta mode. The information provided is for general purposes only and may be incomplete, inaccurate, or out of date. While we strive for accuracy, Theo, the AI agent may make mistakes. We do not guarantee the reliability of the content or any related products and services. No warranties, expressed or implied. Please note, we use data from interactions to train and improve our models.
              </div>   */}
          </motion.div>
        )}
       </AnimatePresence>
    </div>
  );
}
